// React core.
import React, { useEffect, useState } from 'react';

// Firebase.
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'


// Styles
//import styles from './App.module.css'; // This uses CSS modules.
import './main.scss';
import Nav from './components/Nav';
//

// Get the Firebase config from the auto generated file.
const firebaseConfig = {
  apiKey: "AIzaSyDRZNIp2Yuv7mZIgzVaRCK3q-ri2vfxvQk",
  authDomain: "holidayhundred-3fcbd.firebaseapp.com",
  databaseURL: "https://holidayhundred-3fcbd.firebaseio.com",
  projectId: "holidayhundred-3fcbd",
  storageBucket: "holidayhundred-3fcbd.appspot.com",
  messagingSenderId: "626672526297",
  appId: "1:626672526297:web:2b623b86ec2efc31c6efc8",
  measurementId: "G-XQ6QNTGW3E"
};

// Instantiate a Firebase app.
let firebaseApp: firebase.app.App | undefined = undefined;
if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp(firebaseConfig);
}

export const UserContext = React.createContext<firebase.User | null | undefined>(null);

function App() {

  const [isSignedIn, setIsSignedIn] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const unregisterAuthObserver = firebaseApp?.auth().onAuthStateChanged((user) => {
      if(user) {
        const userId = user.uid;
        const ref = firebase.database().ref('/users/' + userId);
        ref.on('value', (snapshot) =>  {
          var data = snapshot.val();
          if(!data?.firstName) {
            firebase.database().ref('users/' + userId).update({
              firstName: user?.displayName
            });
          }
        });
      }
      setIsSignedIn(!!user);
    });
    return () => {
      unregisterAuthObserver && unregisterAuthObserver();
    }
  }, []);

  const currentUser = firebaseApp?.auth().currentUser;

  const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  }

  return !firebaseApp ? null : (
  <UserContext.Provider value={currentUser}>  
    <section className="hero is-primary">
      <div className="hero-body py-4">
        <div className="container">

          <div className="level is-mobile">
            <div className="level-left">
              {/* <img className="level-item mr-2" style={{maxWidth: 80}} src={process.env.PUBLIC_URL + '/logo.png'} alt="Holiday Hundred"/> */}
              <div className="is-block">
                <h1 className="is-size-4">
                  Holiday Hundred
                </h1>
                <h2 className="is-size-7">
                  Stay healthy. Stay connected.
                </h2>
              </div>
            </div>
            <div className="level-right">
              {isSignedIn && <button className="button" onClick={() => firebaseApp?.auth().signOut()}>
                <FontAwesomeIcon icon={faSignOutAlt} />
              </button>}
            </div>
          </div>
          
        </div>
      </div>
    </section>

    {isSignedIn !== undefined && !isSignedIn &&
      <div>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseApp.auth()}/>
      </div>
    }
    {isSignedIn && <>
      <Nav/>
    </>}

  </UserContext.Provider>
  );
}

export default App;
