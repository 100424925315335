import React, { ReactNode } from "react";
import { useState } from "react";
import GroupDashboard from "./GroupDashboard";
import UserDashboard from "./UserDashboard";

export enum Page {
    User, 
    Group
}

function Nav() {
    const [page, setPage] = useState<Page>(Page.User)

    let pageComponent: ReactNode; 
    switch(page) {
        case(Page.User):
            pageComponent = <UserDashboard/>
            break;
        case(Page.Group):
            pageComponent = <GroupDashboard/>
            break;
    }

    return <div>
        <div className="tabs is-fullwidth">
            <ul>
            <li className={`${(page === Page.User) && "is-active"}`}><a href="#0" onClick={() => setPage(Page.User)}>My Dashboard</a></li>
            <li className={`${(page === Page.Group) && "is-active"}`}><a href="#0" onClick={() => setPage(Page.Group)}>Team</a></li>
            </ul>
        </div>
    <div>{pageComponent}</div>
    </div>
}

export default Nav;