import moment from "moment";
import { Entry } from "./models";

export const getAverageMilesNeededPerDay = (currentDate: Date, endDate: Date, entries: Entry[]) => {
    const today = new Date(currentDate.toDateString());

    const totalMilesExceptTodayAndFuture = entries
      .filter(p => new Date(p.date) < today)
      .reduce((sum: number, p: {miles: number, date: string}) => sum + p.miles, 0);

    const daysRemaining = moment(endDate).diff(today, 'days') + 1;
    let milesNeededPerDay = ((100-totalMilesExceptTodayAndFuture) / daysRemaining).toPrecision(3);
    const needed = parseFloat(milesNeededPerDay);
    return (needed >= 0) ? needed : 0 
}

export const getTodayDateOnly = () => new Date(new Date().toDateString())

export const formatNumber = (num: number, decimals: number = 2) => {
  const multiplier = Math.pow(10, decimals);
  return Math.round((num + Number.EPSILON) * multiplier) / multiplier;
}