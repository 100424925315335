import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../App";
import EntriesList from "./EntriesList";
import DateNav from './DateNav';
import MileCount from './MileCount';
import { formatNumber, getAverageMilesNeededPerDay, getTodayDateOnly } from '../utils';
import { Entries, Entry, UserData } from '../models';
import { endDate } from '../constants';
import { Line } from 'rc-progress'; 
import confetti from 'canvas-confetti';

function UserDashboard() {

    const user = useContext(UserContext);
    const [userData, setUserData] = useState<UserData | undefined>(undefined);
    const [date, setDate] = useState<Date>(getTodayDateOnly());

    useEffect(() => {
      const userId = user?.uid;
      const ref = firebase.database().ref('/users/' + userId);
      ref.on('value', (snapshot) =>  {
        var data = snapshot.val();
        setUserData(data);
      });
      return () => {ref.off()};
    }, [user]);

    if(!userData) {
        return null;
    }

    const entries = userData.entries2023 ?? {};
    const entriesValues = Object.values(entries);

    // Filtered the object down to only keys where the value (entry) is for this date
    const entriesToday: Entries = Object.keys(entries)
      .filter(key => entries[key].date === date.toDateString())
      .reduce((obj: { [key: string]: Entry; }, key: string) => {
        obj[key] = userData?.entries2023[key];
        return obj;
    }, {});
    const entriesTodayValues = Object.values(entriesToday);

    const milesToday = formatNumber(
      entriesTodayValues
      .reduce((sum: number, p: {miles: number, date: string}) => sum + p.miles, 0)
    );

    const totalMiles = formatNumber(
      entriesValues
      .reduce((sum: number, p: {miles: number, date: string}) => sum + p.miles, 0)
    );

    let goal = getAverageMilesNeededPerDay(date, endDate, entriesValues);

    const handleAdd = (miles: number) => {
      const newDaily = milesToday + miles;
      const newTotal = totalMiles + miles;
      
      if(newTotal >= 100) {
        fireworks();
      }
      else if(newDaily >= goal) {
        confetti()
      }
    }

    const fireworks = () => {
      var duration = 15 * 1000;
      var animationEnd = Date.now() + duration;
      var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

      function randomInRange(min: any, max: any) {
      return Math.random() * (max - min) + min;
      }

      var interval: any = setInterval(function() {
      var timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
          return clearInterval(interval);
      }

      var particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
      confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
      }, 250);
    }

    return !user? null : <div className="container">
      <div className="section py-0">
        <DateNav date={date} setDate={setDate}/>
        { totalMiles >= 100 ? 
        <div className="has-background-info has-text-white is-size-4 px-3 py-3 my-3"style={{width: "100%"}}>
          Congratulations, you completed the challenge! You rock!
        </div> : null
        }
        <div className="mt-4">
          <Line percent={milesToday/goal*100} strokeWidth={3} trailWidth={3} strokeColor="#48c774"/>
          <div className="has-text-grey is-size-7">You've completed {Math.round(milesToday/goal*100)}% of your daily goal.</div>
        </div>
        <div className="my-5 columns" style={{display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
            <MileCount title="Goal" miles={goal} desc="miles"/>
            <MileCount title="Today" miles={milesToday} desc="miles" big={true}/>
            <MileCount title="Total" miles={totalMiles} desc="miles"/>
        </div>
        {userData && <EntriesList userId={user?.uid} userEntries={entriesToday} date={date} addCallback={handleAdd}/>}
      </div>
    </div>
}

export default UserDashboard;