import React from "react";
import { formatNumber } from "../utils";

export interface MileCountProps {
    title: string,
    miles: number,
    desc: string,
    big?: boolean
}

function MileCount({title, miles, desc, big = false} : MileCountProps) {
    return <div className="column" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <div className={big? "is-size-4" : "is-size-5"}>{title}</div>
        <div className={big? "is-size-2" : "is-size-3"} style={{lineHeight: 1.1}}>{formatNumber(miles)}</div>
        <div>{desc}</div>
    </div>
}

export default MileCount;