import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { endDate, startDate } from "../constants";

export interface DateNavProps {
    date: Date,
    setDate: (date: Date) => void
}

function DateNav(props: DateNavProps) {
    const {date, setDate} = props;

    const addDays = (days: number) => {
        var d = new Date(date);
        d.setDate(d.getDate() + days);
        return d;
    }

    const dayOfWeek = moment(date).format('dddd')
    const fullDate = moment(date).format('MMM Do YYYY');

    return <div className="is-flex" style={{justifyContent: "space-between"}}>
        <button className="button" onClick={() => setDate(addDays(-1))} disabled={date <= startDate}>
            <FontAwesomeIcon icon={faArrowLeft} size="lg"/>
        </button>
        <div className="is-block" style={{textAlign: "center"}}>
            <div className="is-size-4">{dayOfWeek}</div>
            <div>{fullDate}</div>
        </div>
        <button className="button" onClick={() => setDate(addDays(1))} disabled={date >= endDate}>
            <FontAwesomeIcon icon={faArrowRight} size="lg"/>
        </button>
    </div>
}

export default DateNav;