import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import React, { ReactNode, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Entry } from '../models';
import { formatNumber, getTodayDateOnly } from '../utils';

function EntriesList(props: {userId: string, userEntries: {}, date: Date, addCallback: (addedMiles: number) => void}) {
    const {userId, userEntries, date, addCallback} = props;
    const [miles, setMiles] = useState<number | undefined>(undefined);

    const entries = Object.entries(userEntries);
    const isFuture = date > getTodayDateOnly();

    const handleDelete = (key: string, miles: number) => {
        if(window.confirm(`Are you sure you want to delete the entry for ${miles} miles?`)) {
            const refString = 'users/' + userId + '/entries2023/' + key;
            firebase.database().ref(refString).set(null);
        }
    }
    
    const handleAdd = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const validMiles = miles !== undefined && miles > 0; 
        
        if(validMiles && date && userId && !isFuture) {
            firebase.database().ref('users/' + userId).child('entries2023').push().set({
                miles,
                date: date.toDateString()
            });
            setMiles(undefined);
            addCallback(miles || 0);
        }
    };

    return <div style={{display: "flex", flexDirection: "column"}}>
        {<EntryBox>
            <form style={{display: "flex", justifyContent:"space-between", width: "100%"}} onSubmit={handleAdd}>
                <input 
                    value={miles ?? ''} 
                    className="input" 
                    type="number" 
                    step="0.01" 
                    onChange={(e) => {
                        const newMiles = e.currentTarget.value;
                        setMiles(newMiles !== '' ? parseFloat(newMiles) : undefined)}
                    }
                    disabled={isFuture}/>
                <button className="button ml-3" disabled={isFuture}>
                    <FontAwesomeIcon icon={faPlus}/>
                </button>
            </form>
        </EntryBox>}
        {entries && entries.map((arr) => {
            const key = arr[0]
            const entry = arr[1] as Entry;
            return <EntryBox key={key}>
                <div className="is-size-4 is-bold">{formatNumber(entry.miles)}</div>
                <button className="button ml-3" onClick={() => handleDelete(key, entry.miles)} >
                    <FontAwesomeIcon icon={faTrash}/>
                </button>
            </EntryBox>
        })}
    </div>
}

interface EntryBoxProps {
    children: ReactNode
}
function EntryBox({children} : EntryBoxProps) {
    return <div className="box mr-2 mb-2 px-3 py-3" style={{display: "flex", justifyContent: "space-between"}}>
            {children}
        </div>
}

export default EntriesList;