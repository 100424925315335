import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { UserContext } from "../App";
import { Entry, UserData } from '../models';
import { formatNumber } from '../utils';

interface UserMiles {
  firstName: string, 
  miles: number
}

function GroupDashboard() {

    const user = useContext(UserContext);
    const [usersData, setUsersData] = useState<{} | undefined>(undefined);

    useEffect(() => {
        const ref = firebase.database().ref('users');
        ref.on('value', (snapshot) =>  {
            setUsersData(snapshot.val());
        });
        return () => {ref.off()};
    }, [user]);

    if(usersData === undefined) {
        return null;
    }
    
    const users: UserData[] = Object.values(usersData) as UserData[];
    let mileTotals: UserMiles[] = users.map((u) => {
      const firstName = u.firstName;
      const entries = Object.values(u.entries2023 || {});
      const miles = formatNumber(
        entries.reduce((sum: number, p: Entry) => sum + p.miles, 0)
      );
      return {
        firstName,
        miles
      }
    });

    // Only show users with entries
    mileTotals = mileTotals.filter((mt) => mt.miles > 0);

    // sort highest first
    mileTotals = mileTotals.sort((a,b) => a.miles > b.miles ? -1 : a.miles < b.miles ? 1 : 0);

    const data = {
        labels: mileTotals.map((u) => u.firstName),
        datasets: [{
          label: 'Leaderboard',
          backgroundColor: 'rgb(72, 199, 116, 0.5)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgb(72, 199, 116)',
          data: mileTotals.map((u) => u.miles),
        }]
    };

    return <div className="container">
      <div className="section py-0">
        <Bar
      data={data}
      options={{
        plugins: {
          datalabels: {
          }
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
                suggestedMax: 100
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false
              },
            }
          ]
      }}}
    />
    </div>
  </div>
}

export default GroupDashboard;